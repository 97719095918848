import { Propietario } from './propietario';
import { Conductor } from './conductor';
import { Cliente } from './cliente';

export class Usuario {
    IDUsuario: number;
    Nombre: string;
    Email: string;
    Contrasena: string;
    Tipo: number;

}
